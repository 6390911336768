<template>
  <div class="shop-list">
    <!-- 搜索页面 -->
    <template v-if="type == 'scarch' || type == 'filtrate'">
      <Tabs
        v-if="childTabArr"
        v-model="active"
        :swipe-threshold="5"
        background="#ffffff"
        line-width="25px"
        line-height="2px"
        sticky
        @change="setActive"
      >
        <Tab
          :title="item.name"
          v-for="(item, idx) in childTabArr"
          :key="idx"
        ></Tab>
      </Tabs>
      <List
        class="shop-list-con"
        v-model="loading"
        :finished="finished"
        finished-text="加载完成..."
        :error.sync="error"
        @load="getList"
        error-text="请求失败，点击重新加载"
      >
        <div
          :class="
            `shop-list-item animation-${idx + 1} ${
              styleStore ? '' : 'shop-list-item-on'
            }`
          "
          v-for="(item, idx) in listdata"
          :key="idx"
          @click="goDetail(item.id)"
        >
          <span
            class="iconfont icondel del-icon"
            @click.stop="del_good(item)"
          ></span>
          <div
            class="shop-list-top"
            :class="styleStore ? '' : 'shop-list-top-on'"
          >
            <img :src="item.image[0].url" alt />
          </div>
          <div
            class="shop-list-bot"
            :class="styleStore ? '' : 'shop-list-bot-on'"
          >
            <div
              class="shop-list-title"
              :class="styleStore ? '' : 'shop-list-title-on'"
            >
              {{ item.name }}
            </div>
            <div class="shop-list-label" v-if="item.labels.length">
              <div
                class="shop-list-quan"
                v-for="(labels, idx) in item.labels"
                :key="idx"
                style="border:1px solid #ff8000"
              >
                {{ labels.value }}
              </div>
            </div>
            <div class="shop-list-pay">
              <div>
                <span>￥</span>
                <span style="font-size:16px">{{ item.price }}</span>
              </div>
              <div class="shop-list-ping">{{ item.comments_count }}评价</div>
            </div>
          </div>
        </div>
      </List>
    </template>
    <template v-else-if="type == 'other'">
      <List
        class="shop-list-con"
        v-model="loading"
        :finished="finished"
        finished-text="加载完成..."
        :error.sync="error"
        @load="getList"
        error-text="请求失败，点击重新加载"
      >
        <div
          :class="`shop-list-item animation-${idx + 1}`"
          v-for="(item, idx) in listdata"
          :key="idx"
          @click="goDetail(item.id)"
        >
          <span
            class="iconfont icondel del-icon"
            @click.stop="del_good(item)"
          ></span>
          <div class="shop-list-top">
            <img :src="item.image[0].url" alt />
          </div>
          <div class="shop-list-bot">
            <div class="shop-list-title">{{ item.name }}</div>
            <div class="shop-list-label" v-if="item.labels.length">
              <div
                class="shop-list-quan"
                v-for="(labels, idx) in item.labels"
                :key="idx"
                style="border:1px solid #ff8000"
              >
                {{ labels.value }}
              </div>
            </div>
            <div class="shop-list-pay">
              <div>
                <span>￥</span>
                <span style="font-size:16px">{{ item.price }}</span>
              </div>
              <div class="shop-list-ping">{{ item.comments_count }}评价</div>
            </div>
          </div>
        </div>
      </List>
    </template>
    <template v-else-if="type == 'store'">
      <Tabs
        v-if="storeData"
        v-model="active"
        :swipe-threshold="5"
        background="#ffffff"
        line-width="25px"
        line-height="2px"
        sticky
        @change="setActive"
      >
        <Tab :title="item" v-for="(item, idx) in storeData" :key="idx"></Tab>
      </Tabs>
      <List
        class="shop-list-con"
        v-model="loading"
        :finished="finished"
        finished-text="加载完成..."
        :error.sync="error"
        @load="getList"
        error-text="请求失败，点击重新加载"
      >
        <div
          :class="`shop-list-item animation-${idx + 1}`"
          v-for="(item, idx) in listdata"
          :key="idx"
          @click="goDetail(item.id)"
        >
          <span
            class="iconfont icondel del-icon"
            @click.stop="del_good(item)"
          ></span>
          <div class="shop-list-top">
            <img :src="item.image[0].url" alt />
          </div>
          <div class="shop-list-bot">
            <div class="shop-list-title">{{ item.name }}</div>
            <div class="shop-list-label" v-if="item.labels.length">
              <div
                class="shop-list-quan"
                v-for="(labels, idx) in item.labels"
                :key="idx"
                style="border:1px solid #ff8000"
              >
                {{ labels.value }}
              </div>
            </div>
            <div class="shop-list-pay">
              <div>
                <span>￥</span>
                <span style="font-size:16px">{{ item.price }}</span>
              </div>
              <div class="shop-list-ping">{{ item.comments_count }}评价</div>
            </div>
          </div>
        </div>
      </List>
    </template>
    <template v-else>
      <Tabs
        v-if="childTabArr"
        v-model="active"
        :swipe-threshold="5"
        background="#ffffff"
        line-width="25px"
        line-height="2px"
        sticky
        @change="setActive"
      >
        <Tab
          :title="item.name"
          v-for="(item, idx) in childTabArr"
          :key="idx"
        ></Tab>
      </Tabs>
      <List
        class="shop-list-con"
        v-model="loading"
        :finished="finished"
        finished-text="加载完成..."
        :error.sync="error"
        @load="getList"
        error-text="请求失败，点击重新加载"
      >
        <div
          :class="`shop-list-item animation-${idx + 1}`"
          v-for="(item, idx) in listdata"
          :key="idx"
          @click="goDetail(item.id)"
        >
          <span
            class="iconfont icondel del-icon"
            @click.stop="del_good(item)"
          ></span>
          <div class="shop-list-top">
            <img :src="item.image[0].url" alt />
          </div>
          <div class="shop-list-bot">
            <div class="shop-list-title">{{ item.name }}</div>
            <div class="shop-list-label" v-if="item.labels.length">
              <div
                class="shop-list-quan"
                v-for="(labels, idx) in item.labels"
                :key="idx"
                style="border:1px solid #ff8000"
              >
                {{ labels.value }}
              </div>
            </div>
            <div class="shop-list-pay">
              <div>
                <span>￥</span>
                <span style="font-size:16px">{{ item.price }}</span>
              </div>
              <div class="shop-list-ping">{{ item.comments_count }}评价</div>
            </div>
          </div>
        </div>
      </List>
    </template>
  </div>
</template>
<script>
import { Tab, Tabs, List } from "vant";
import Goods from "@/api/goods";
export default {
  components: {
    Tab,
    Tabs,
    List
  },
  props: {
    type: {
      type: String,
      default: "store"
    },
    showTabs: {
      type: Boolean,
      default: true
    },
    url_status: {
      type: Boolean,
      default: true
    },
    childTabArr: {
      type: Array
    },
    firstId: {
      type: String
    },
    name: {
      type: String
    },
    searchId: {
      type: String
    },
    // 搜索列表样式
    styleStore: {
      type: Boolean,
      default: true
    }
  },
  watch: {
    type() {
      this.page = 0;
      this.listdata = [];
      this.getList();
    }
  },
  data() {
    return {
      active: 0,
      sort_id: this.$route.query.goods_id,
      listdata: [],
      loading: false,
      error: false,
      finished: false,
      page: 0,
      tabs: ["精选", "好店", "百货", "手机", "分类"],
      storeData: ["推荐", "销量", "新品", "价格"]
    };
  },
  methods: {
    setActive() {
      this.page = [];
      this.listdata = [];
      this.getList();
    },
    del_good(item) {
      this.$toast(`删除${item.id}`);
    },
    // 获取商品列表
    async getList() {
      try {
        this.page++;
        var res = {};
        switch (this.type) {
          // 搜索页面
          case "other":
            res = await Goods.searchGoods(this.page, this.searchId);
            break;
          case "scarch":
            res = await Goods.getShopGoodsListy(this.page, "", this.name);
            break;
          case "index":
            res = await Goods.getShopGoodsListy(this.page);
            break;
          // 详情页
          case "detail":
            res = await Goods.getShopGoodsListy(this.page);
            break;
          case "sort":
            res = await Goods.getShopGoodsListy(this.page, this.sort_id);
            break;
          // 店铺
          case "store":
            if (this.active == 0) {
              res = await Goods.getShopGoodsListy(this.page, this.firstId);
            } else {
              res = await Goods.getShopGoodsListy(
                this.page,
                this.childTabArr[this.active].id
              );
            }
            break;
          default:
            break;
        }
        if (res.code == 200) {
          if (res.total > 0) {
            res.data.forEach(element => {
              this.listdata.push(element);
            });
            this.loading = false;
            this.finished = this.listdata.length == res.total ? true : false;
          } else {
            this.loading = false;
            this.finished = true;
          }
        } else {
          this.loading = false;
          this.finished = true;
          this.$toast(res.msg);
        }
      } catch (err) {
        console.log(err);
      }
    },
    goDetail(id) {
      this.$router.push({
        path: "/ShopDetail",
        query: {
          goods_id: id
        }
      });
      if (this.url_status) {
        return;
      } else {
        this.$router.go(0);
      }
    }
  },
  beforeDestroy() {
    // 清空数据
    this.page = 0;
    this.listdata = [];
    this.finished = false;
  }
};
</script>
<style lang="scss" scoped>
.shop-list {
  width: 100%;
  height: auto;
  .shop-list-con {
    width: 100%;
    height: auto;
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;

    .shop-list-item {
      width: 46%;
      position: relative;
      height: auto;
      display: flex;
      background: #fff;
      margin-left: 10px;
      margin-top: 10px;
      flex-direction: column;
      .del-icon {
        position: absolute;
        top: 6px;
        right: 10px;
      }
      .shop-list-top {
        width: 100%;
        height: 160px;
        padding: 5px;
        img {
          width: 100%;
          height: 100%;
        }
      }
      .shop-list-top-on {
        width: 120px;
        height: 120px;
      }
      .shop-list-bot {
        width: 100%;
        height: auto;
        display: flex;
        color: #4c4c4c;
        font-size: 13px;
        padding: 5px;
        flex-direction: column;
        .shop-list-title {
          width: 100%;
          font-size: 12px;
          line-height: 15px;
        }
        .shop-list-label {
          width: 100%;
          height: 25px;
          display: flex;
          margin-left: -2%;
          .shop-list-quan {
            width: auto;
            height: 20px;
            line-height: 20px;
            border-radius: 2px;
            font-size: 10px;
            color: #ff8000;
            font-weight: 500;
            margin-top: 5px;
            padding: 0 5px;
            border: 1px solid #ff8000;
            margin-left: 2%;
          }
        }
        .shop-list-pay {
          width: 100%;
          line-height: 15px;
          display: flex;
          margin-top: 8px;
          .shop-list-ping {
            color: #4c4c4c;
            margin-left: 10px;
          }
          div {
            color: #ff8000;
            font-size: 12px;
          }
        }
      }
      .shop-list-bot-on {
        flex: 1;
        margin-left: 5px;
        .shop-list-title-on {
          font-size: 16px;
          line-height: 20px;
        }
      }
    }
    .shop-list-item-on {
      width: 95%;
      flex-direction: row;
    }
  }
}
</style>
